<template>
  <div v-if="data">
    <form-model @valid="editModel" :disabled="disabled" :data="data" ref="model"/>
  </div>
</template>

<script>
import formModel from './formModel'

export default {
  name: 'editModel',
  components: {
    formModel
  },
  created() {
    this.getModel()
  },
  props: {
    disabled: {
      default: true
    },
    modelId: {
      required: true,
    },
    makeId: {
      required: true
    }
  },
  data() {
    return {
      isDisabled: false,
      data: null
    }
  },
  methods: {
    async getModel() {
      try {
        const {data} = await this.$api.model.get(this.modelId)
        this.data = {
          model: data.name
        }
      } catch (e) {
      }
    },
    async editModel(form) {
      this.isDisabled = true

      try {
        const {data} = await this.$api.model.update(form.model, this.makeId, this.modelId)
        this.$refs.model.submit(data, false, 'Model naam aangepast')
      } catch (e) {
        this.$toasted.show('Model aanmaken mislukt')
      } finally {
        this.isDisabled = false
      }
    }
  }
}
</script>
